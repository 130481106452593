@font-face {
	font-family: Primary-Penmanship-2;
	src: url("./fonts/KGPrimaryPenmanship2.ttf");
}
body {
	background-color: #fffec2;
}

.mainTitle {
	font-family: "Primary-Penmanship-2", monospace;
	font-size: 40px;
}

.mainButtons {
	width: 400px;
	font-family: "Primary-Penmanship-2";
	background-color: #ffd4fb;
	color: black;
	height: 200px;
	font-size: 30px;
	border-radius: 0px;
	box-shadow: 10px 5px 5px #d6d6b0;
}

.mainButtons:disabled {
	color: #aaa;
}

.gameButtons {
	width: 400px;
	font-family: "Primary-Penmanship-2";
	background-color: white;
	color: black;
	height: 200px;
	font-size: 30px;
	border-radius: 0px;
	box-shadow: 10px 5px 5px #d6d6b0;
}

.gameTextBox {
	width: 200px;
	font-family: "Primary-Penmanship-2";
	background-color: white;
	color: black;
	height: 100px;
	font-size: 30px;
	border-radius: 0px;
	box-shadow: 10px 5px 5px #d6d6b0;
	text-align: center;
	text-justify: center;
	border-color: black;
	border-width: 1px;
	border-style: solid;
}

.operatorTextBox {
	width: 100px;
	font-family: "Primary-Penmanship-2";
	background-color: white;
	color: black;
	height: 50px;
	font-size: 30px;
	border-radius: 0px;
	box-shadow: 10px 5px 5px #d6d6b0;
	text-align: center;
	text-justify: center;
	border-color: black;
	border-width: 1px;
	border-style: solid;
}

.gameInputBox {
	width: 200px;
	height: 50px;
	font-family: "Primary-Penmanship-2";
	font-size: 30px;
	text-align: center;
	text-justify: center;
}

.gameSubmitButton {
	height: 50px;
	font-family: "Primary-Penmanship-2";
	color: black;
	font-size: 30px;
}

.voiceButton {
	height: 50px;
	font-family: "Primary-Penmanship-2";
	color: black;
	font-size: 30px;
}

.backButton {
	margin-left: 10px;
	margin-top: 10px;
	width: 75px;
	font-family: "Primary-Penmanship-2";
	background-color: #ffd4fb;
	color: black;
	height: 40px;
	font-size: 20px;
}

.homeButton {
	margin-top: 10px;
	margin-left: 10px;
	width: 75px;
	font-family: "Primary-Penmanship-2";
	background-color: #ffd4fb;
	color: black;
	height: 40px;
	font-size: 20px;
}
